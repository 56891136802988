@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

@mixin audit-risk-text() {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #49454f;
}

$slidersBorderRadius: 24px;

@mixin overlays() {
  width: 580px;
  margin-top: -30px;
  height: 280px;
  position: absolute;
  border-radius: $slidersBorderRadius;
}

.Sandbox {
  &.success-container {
    margin: 0 auto;
    background: #fafafd;
    box-shadow: 0px 8px 16px rgba(0, 49, 93, 0.2);
    border-radius: 28px;
    padding: 40px 48px 30px;
    max-width: 880px;
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      @include headline-large("Manrope");
      margin-bottom: 16px;
    }

    a {
      margin-top: 24px;
    }

    .success-msg-container {
      padding: 32px 65px;
      background: #f2faf3;
      border-radius: 4px;
      max-width: 784px;
      margin: 24px auto;

      p {
        @include body-medium;
        color: #008862;
      }
    }

    .success-link {
      @include text-only-button(underline);
      color: $InitioBlue;
      margin-top: "24px";
    }
  }

  &.PriceSandbox {
    .back-link-block {
      max-width: 1200px;
      margin: auto;
      margin-top: 24px;
      margin-bottom: 12px;

      h4 {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        color: #0061a4;
      }

      .back-link-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100px;
        color: #0061a4;
        background-color: transparent;
        border-width: 0px;
        padding: 0px;
        &:hover {
          opacity: 0.8;
        }

        .MuiSvgIcon-root {
          margin-top: 2px;
        }
      }
    }

    .valuation-container {
      $leftColWidth: 640px;
      $rightColWidth: 288px;
      $marginBetweenCols: 24px;

      width: $leftColWidth + $rightColWidth + $marginBetweenCols;
      margin: 0 auto;

      margin-bottom: 45px;

      .headline-container {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 969px;
        margin-bottom: 40px;

        button.MuiButtonBase-root {
          &.MuiButton-root {
            &.MuiButton-contained {
              margin-left: 16px;

              border-radius: 100px;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.075px;
              color: #ffffff;
              font-size: 12px;

              &.top-btn-instance {
                filter: drop-shadow(2px 5px 4px rgba(0, 49, 93, 0.2));
                @include tonal-contained-button;
                height: 40px;

                &.finalize {
                  @include blue-contained-button;
                  background-color: $Primary50;

                  .MuiSvgIcon-root {
                    height: 18px;
                    width: 18px;
                    margin-bottom: 3px;
                  }
                }
              }

              &.secondary-top-btn {
                background: #d0d9ee;
                color: #303d5c;

                &:hover {
                  box-shadow: none !important;
                  background-color: #d1e4ff;
                }

                &:focus-visible {
                  border: 2px solid #4295f3;
                }

                &.printer-btn {
                  $printerBtnWidth: 29px;
                  width: $printerBtnWidth;
                  min-width: $printerBtnWidth;
                  padding: 6px 18px;
                  width: 143px;
                  height: 40px;
                  white-space: nowrap;

                  svg {
                    margin-right: 5px;
                    width: 18px;
                  }
                }

                &.refresh-btn {
                  width: 103px;

                  svg {
                    margin-right: 5px;
                    width: 18px;
                  }
                }

                &.optimize-btn {
                  width: 123px;

                  svg {
                    margin-right: 5px;
                    width: 18px;
                  }
                }
              }
            }
          }
        }

        h2 {
          display: inline-block;
          font-family: "Manrope", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          color: #49454f;
          margin-top: 4px;
        }
      }

      .sandbox-scrolling-wrapper {
        position: relative;

        .loading-wrapper {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          border-radius: 24px;
          z-index: 999;
          background: #fafafd;
          opacity: 0.8;
          display: flex;
          justify-content: center;
          align-items: center;

          .custom-loading-spinner {
            $spinnerSize: 62px;
            height: $spinnerSize;
            width: $spinnerSize;
            @include spin-infinitely();
          }
        }

        &.user-view {
          padding: 0px 20px 20px 0px;
          height: 72vh;

          &::-webkit-scrollbar {
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            border: 5px solid transparent;
            border-radius: 100px;
            background-color: #999ba5;
            background-clip: content-box;
          }
        }

        padding-right: 20px;
        padding-top: 5px;
        width: $leftColWidth + $rightColWidth + 45px;

        .top-container {
          @include spaced-between-flexbox;
          gap: 24px;

          .concluded-value {
            @include spaced-between-flexbox(center, nowrap, column);
            align-items: center;
            font-family: "Roboto";
            max-width: 300px;
            min-width: 288px;
            position: relative;
            color: #ffffff;
            border-radius: 24px;
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);

            h6 {
              display: flex;
              position: absolute;
              top: 17px;
              width: 201px;
              height: 20px;
              font-weight: 500;
              margin-bottom: 0px;
              align-items: flex-end;
              font-size: 14px;
            }

            .price-container {
              @include spaced-between-flexbox(space-around);
              padding-right: 18px;

              .share-price {
                font-feature-settings: "tnum";
                -webkit-font-feature-settings: "tnum";
                -moz-font-feature-settings: "tnum";
                font-kerning: auto;
                display: flex;
                letter-spacing: -0.11rem;
                font-family: "Manrope";
                font-style: normal;
                font-weight: 300;
                font-size: 48px;
                line-height: 60px;
                direction: ltr;
                margin-top: 16px;

                span {
                  margin-right: 2px;
                  font-size: 46px;
                }
              }

              span {
                display: flex;
                left: 12px;
                flex-grow: 1;
                font-size: 48px;
              }

              p {
                display: flex;
                justify-content: center;
                align-items: center;
                left: 8.5rem;
                bottom: 1.85rem;
                font-family: "Roboto";
                white-space: nowrap;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.075px;
                margin-top: 15px;
                margin-left: 7px;
              }
            }
          }

          .top-explanation {
            display: flex;
            align-items: center;
            min-height: 114px;
            background: #fafafd;
            border-radius: 24px;
            min-width: 618px;
            box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
            padding: 24px 40px;
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.25px;
            color: #49454f;
          }
        }

        .info-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-between;

          .info-container-left-col {
            width: $leftColWidth;
            margin-right: 12px;

            .updated-price-loading-wrapper {
              .updated-price-loading-overlay-text {
                @include overlays();
                z-index: 25;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: center;
                align-items: center;

                .custom-loading-spinner {
                  $spinnerSize: 48px;
                  height: $spinnerSize;
                  width: $spinnerSize;
                  margin-bottom: 17px;
                  @include spin-infinitely();
                }

                span {
                  @include headline-medium;
                }
              }

              .updated-price-loading-overlay {
                @include overlays();
                z-index: 10;
                opacity: 0.8;
                height: 350px;
                background: #fafafd;
              }
            }

            .audit-risk-info-and-slider {
              display: flex;
              flex-direction: column;
              padding: 24px 32px 32px;
              background: #fafafd;
              box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
              border-radius: 24px;
              margin-top: 24px;
              min-height: 366px;

              h3 {
                height: 28px;
                font-size: 24px;
                font-weight: 400;
                margin: 0px 0px 16px 10px;
              }

              .slider-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                background: #f0f0f3;
                width: 576px;
                height: 242px;
                border-radius: 12px;

                .price-slider-header {
                  display: flex;
                  align-items: flex-start;
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 20px;
                  letter-spacing: 0.075px;
                  width: 576px;
                  font-weight: 600;
                  padding: 0px 14px 0px;
                  margin: 6px 0px 0px 0px;
                  flex: none;
                  order: 0;
                  flex-grow: 0;
                }

                .median {
                  position: absolute;
                  left: 46.4%;
                  top: 16.5%;
                  padding: 0px;
                  margin: 0px;
                  margin-top: 0px;
                  width: 30px;
                  height: 16px;
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 16px;
                  letter-spacing: 0.5px;
                }

                .iterations-bar {
                  display: flex;
                  justify-content: space-around;
                  flex-direction: row;
                  width: 544px;
                  height: 70px;
                  background: #eae9ec;
                  padding: 8px 12px;
                  border-radius: 8px;
                  margin-bottom: 8px;
                }
              }

              .increment-info {
                display: flex;
                justify-content: flex-end;
                width: 583px;

                p {
                  display: flex;
                  margin: 16px 0px 0px !important;
                  font-family: "Roboto";
                  font-style: normal;
                  font-weight: 500;
                  font-size: 11px;
                  line-height: 16px;
                  letter-spacing: 0.5px;
                  height: 16px;
                  color: #49454f;
                  text-align: center;
                  flex: none;
                  order: 0;
                  flex-grow: 0;
                }

                .incrementalBtn {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin: 13px 0px 0px !important;
                  height: 21px;
                  min-width: 21px !important;
                  padding: 0px;
                  border-radius: 16px;

                  .MuiSvgIcon-root {
                    display: flex;
                    align-items: center;
                    height: 13px;
                    width: 13px;
                    color: #0061a4;
                  }

                  &:hover {
                    margin: 13px 0px 0px !important;
                    background-color: #0060a40f;
                    height: 21px;
                    width: 21px;
                    border-radius: 15px;
                  }

                  &:focus-visible {
                    margin: 13px 0px 0px !important;
                    display: flex;
                    background: rgba(0, 97, 164, 0.1);
                    border: 2px solid #4295f3 !important;
                    height: 27px;
                    width: 28px;
                    border-radius: 15px;
                  }

                  &:focus-visible {
                    margin: 13px 0px 0px -2px !important;
                    display: flex;
                    background: rgba(0, 97, 164, 0.1);
                    border: 2px solid #4295f3 !important;
                    height: 21px;
                    width: 21px;
                    border-radius: 15px;
                  }

                  &:focus {
                    margin: 13px 0px 0px !important;
                    display: flex;
                    border: 1px solid #007bcc;
                    height: 21px;
                    width: 21px;
                    border-radius: 15px;
                  }
                }
              }

              p {
                @include audit-risk-text();
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.25px;
              }
            }

            .outcomes-text-block {
              padding: 32px;
              background: #fafafd;
              box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
              border-radius: 24px;
              margin-top: 24px;
              margin-bottom: 24px;

              p.outcomes-text {
                font-family: Roboto, Arial, Sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                letter-spacing: 0.25px;
                margin-bottom: 0;
              }
            }

            .key-terms-block {
              padding: 40px;
              background: #fafafd;
              border-radius: 24px;

              h3.key-terms-title {
                font-family: Roboto, Arial, Sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
                color: #49454f;
                margin-bottom: 36px;
              }

              div.key-terms-entry {
                border-left: 4px solid #40c486;
                padding-left: 12px;

                p.key-terms-explanation {
                  font-family: Roboto, Arial, Sans-serif;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 22px;
                  letter-spacing: 0.25px;
                  margin-bottom: 24px;

                  span.key-term {
                    font-weight: 700;
                  }
                }
              }
            }
          }

          .info-container-right-col {
            width: $rightColWidth;
            margin-left: 12px;
            margin-top: 24px;

            .right-col-wrapper {
              background: #fafafd;
              box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
              border-radius: 24px;
              padding: 24px 44px 40px;

              h3 {
                @include audit-risk-text();
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 20px;
              }

              h4 {
                @include audit-risk-text();
                font-size: 16px;
                line-height: 24px;
              }

              hr {
                width: 192px;
                margin: 26px auto 22px;
              }
            }
          }
        }
      }
    }
  }
}

.no-outcome-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;

    .box-header {
      @include body-large;
      @include spaced-between-flexbox();
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin-bottom: 21px;

      .MuiSvgIcon-root {
        color: $Tertiary50;
        height: 36px;
        width: 36px;
      }

      h4 {
        @include headline-medium;
        color: $Tertiary50;
        font-weight: 400;
        margin: 0;
      }
    }

    .got-it-btn {
      @include blue-contained-button;
      width: auto;
      margin-left: auto;
    }
  }
}

.finalize-arrow-tooltip {
  display: flex;
  flex-direction: row;

  .MuiTooltip-tooltip {
    display: flex;
    background-color: #00497d !important;
    @include body-large;
    color: #ffffff;
    min-width: 309px;
    padding: 16px 0px 16px 24px !important;
    border-radius: 8px !important;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    gap: 8px;

    .MuiTooltip-arrow {
      &::before {
        background-color: #00497d !important;
      }
    }

    .exit-tooltip {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      padding: 0px;
      height: 25px !important;
      width: 25px !important;
      color: #d1e4ff;

      .MuiSvgIcon-root {
        display: flex;
        height: 24px;
        width: 24px;
      }

      &:focus-visible {
        background-color: #04385d99;
        border-radius: 6px;
      }

      &:hover {
        background-color: #04385d99;
        border-radius: 6px;
        border: #ffffff;
      }
    }
  }
}

.optimize-dropdown {
  margin-top: 8px;

  .MuiPaper-root {
    @include select-dropdown;

    .active {
      background-color: rgba(66, 149, 243, 0.08);
      box-shadow: 4px 0 0 $InitioBlue inset;
    }
  }
}
