@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.report-viewer {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  .react-pdf__Page.prevPage {
    position: absolute !important;
    z-index: 1;
  }
  .react-pdf__Document{
    height: 792px;
  }
}

.pdf-loading-wrapper {
  @include spaced-between-flexbox(center, nowrap, column);
  align-items: center;
  margin: 160px;
  gap: 24px;
  span {
    @include title-medium;
  }
  .custom-loading-spinner {
    $spinnerSize: 60px;
    height: $spinnerSize;
    width: $spinnerSize;
    @include spin-infinitely();
  }
}



