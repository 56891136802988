@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.TransactionStatusBlock {
  position: relative;
  .transaction-block-btn {
    background-color: white;
    border: 1px solid #cccccc;
    border-radius: 8px;
    width: 312px;
    padding: 24px;
    padding-bottom: 0;
    margin-bottom: 12px;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    overflow-y: auto;
    cursor: default;
    &.can-hover {
      &:hover {
        cursor: pointer;
        background-color: #ecf2f8;
        .note-field {
          .MuiInputBase-root {
            background-color: #ecf2f8;
          }
        }
      }
      &:focus-visible {
        outline: none;
        border: 2px solid $InitioBlue;
      }
    }
    .transaction-block-header {
      margin-bottom: 24px;
      .transaction-block-info-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        .accounting-service-name {
          background: #303d5c;
          border-radius: 12px;
          padding: 8px 12px;
          width: 64px;
          height: 40px;
          color: #eff0f3;
          font-family: "Manrope";
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
        }
        .transaction-name-and-info-wrapper {
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          text-align: left;
          .top-transaction-name {
            @include label-large;
          }
          .bottom-transaction-info {
            @include body-medium;
          }
        }
        .priority-icon-and-or-chevron-btn {
          @include spaced-between-flexbox(center);
          align-items: center;
          margin-left: auto;
          .notif-icon {
            color: #ffc043;
          }
          .right-chevron-btn {
            height: 32px;
            width: 32px;
            background: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            &:hover {
              background-color: #e6f0f6;
              cursor: pointer;
            }
            &:focus-visible {
              outline: 2px solid $InitioBlue;
            }
            &:active {
              background-color: #99c0db;
            }
          }
        }
      }
      .priority-icon-and-or-status-chip {
        @include spaced-between-flexbox(flex-end);
        align-items: center;
        gap: 14px;
        .notif-icon {
          color: #ffc043;
        }
      }
      .unarchive-btn {
        @include outlined-button;
        height: 28px;
        margin-left: 140px;
      }
    }
    .above-dropdown-spacer {
      margin: 0;
    }
    .dropdown-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 24px;
      .files-list {
        width: 256px;
        background: rgba(0, 72, 132, 0.08);
        border-radius: 12px;
        padding: 8px 10px;
        margin-bottom: 16px;
        .files-list-header {
          @include spaced-between-flexbox(flex-start);
          align-items: center;
          span {
            @include body-small;
            font-size: 12px;
            margin-left: 4px;
          }
          .file-list-chevron-btn {
            @include spaced-between-flexbox(center);
            align-items: center;
            margin-left: auto;
            .right-chevron-btn {
              margin-left: auto;
              height: 32px;
              width: 32px;
              background: none;
              border: none;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              &:hover {
                background-color: #e6f0f6;
                cursor: pointer;
              }
              &:focus-visible {
                outline: 2px solid $InitioBlue;
              }
              &:active {
                background-color: #99c0db;
              }
            }
          }
        }
        .above-dropdown-spacer {
          margin-bottom: 4px;
        }
        .loading-file-list {
          @include spaced-between-flexbox(center);
          align-items: center;
          height: 40px;
          .custom-loading-spinner {
            $spinnerSize: 24px;
            height: $spinnerSize;
            width: $spinnerSize;
            @include spin-infinitely();
          }
        }
        .file-el {
          font-size: 12px;
          @include spaced-between-flexbox(flex-start);
          gap: 6px;
          align-items: center;
          span {
            @include body-small;
            word-break: break-word;
          }
          .MuiButtonBase-root {
            padding: 0;
            @include btn-to-link-text;
            @include body-small;
            color: $InitioBlue;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .MuiInputBase-root {
        padding: 10px;
        border-radius: 12px;
        @include body-small;
        padding: 10px;
        font-size: 14px;
        font-size: 14px;
        width: 256px;
        height: 120px;
        font-size: 14px;
        width: 256px;
        height: 120px;
        background-color: white;
        resize: none;
      }
      .multiline-input {
        position: relative;
        textarea {
          width: 256px;
          height: 80px;
          border-radius: 12px;
          @include body-small;
          padding: 10px;
          font-size: 14px;
          background-color: white;
          resize: none;
          &::-webkit-scrollbar {
            display: none;
          }
          &::placeholder {
            color: #999ba5;
          }
          &:focus-visible {
            outline: 2px solid $InitioBlue;
          }
        }
        .MuiButtonBase-root {
          position: absolute;
          right: 12px;
          bottom: 12px;
          .MuiSvgIcon-root {
            color: #999ba5;
          }
        }
      }
      .note-length {
        @include body-small;
        text-align: right;
        margin-top: 2px;
        margin-right: 4px;
        margin-bottom: 8px;
      }
      .note-list {
        .edit-note {
          background-color: white;
          padding: 12px 16px;
          border: 2px solid $InitioBlue;
          border-radius: 12px;
          width: 256px;
          * {
            border: none !important;
            box-shadow: none;
          }
          textarea {
            width: 160px;
            height: 80px;
            border-radius: 12px;
            @include body-small;
            padding: 10px;
            font-size: 14px;
            background-color: white;
            resize: none;
            &::-webkit-scrollbar {
              display: none;
            }
            &::placeholder {
              color: #999ba5;
            }
            &:focus-visible {
              outline: none;
            }
          }
          .edit-note-btns {
            margin-top: 5px;
            @include spaced-between-flexbox(flex-end);
            gap: 10px;
            .edit-note-cancel-btn {
              @include outlined-button;
              border: 1px solid #315880 !important;
              height: 24px;
            }
            .edit-note-save-btn {
              @include blue-contained-button;
              height: 24px;
            }
          }
        }
        .note {
          position: relative;
          @include spaced-between-flexbox(flex-start, nowrap, column);
          margin-right: auto;
          margin-left: 4px;
          margin-right: 4px;
          border-radius: 12px;
          padding: 16px 12px;
          .loading-note {
            display: none;
          }
          &.isEditing {
            position: relative;
            background: rgba(0, 72, 132, 0.08);
            * {
              visibility: hidden;
            }
            .loading-note {
              * {
                visibility: visible;
              }
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              border-radius: 24px;
              z-index: 999;
              display: flex;
              justify-content: center;
              align-items: center;
              .custom-loading-spinner {
                $spinnerSize: 24px;
                height: $spinnerSize;
                width: $spinnerSize;
                @include spin-infinitely();
              }
            }
          }
          &:hover {
            background: rgba(0, 72, 132, 0.08);
          }
          .note-date-time {
            .note-author {
              @include label-large;
            }
            align-items: center;
            @include body-small;
            .note-delete {
              @include spaced-between-flexbox(flex-end);
              .MuiButtonBase-root {
                padding: 0px;
                &:hover {
                  cursor: pointer;
                  opacity: 0.8;
                  background-color: white;
                }
              }
            }
          }
          .note-content {
            @include body-medium;
            word-wrap: normal;
          }
          .note-vert-icon {
            position: absolute;
            color: #999ba5;
            border-radius: 50%;
            height: 32px;
            width: 32px;
            padding: 2px;
            right: 8px;
            top: 8px;
            &:hover {
              background-color: rgba(0, 97, 164, 0.1);
              box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85) !important;
            }
            &:focus-visible {
              background-color: rgba(0, 97, 164, 0.1);
              outline: 2px solid $InitioBlue;
            }
            &:active {
              background-color: rgba(0, 97, 164, 0.4);
            }
          }
        }
      }
      .submit-info {
        background: rgba(0, 72, 132, 0.08);
        border-radius: 12px;
        margin-top: 8px;
        margin-bottom: 12px;
        padding: 12px;
        @include body-small;
        .go-to-company-page-btn {
          @include body-small;
          @include btn-to-link-text;
          color: $Primary40;
          font-weight: 600;
          padding: 0;
        }
        .account-detail {
          @include btn-to-link-text;
          @include body-small;
          text-align: left;
          color: $Primary40;
          padding: 0;
          font-weight: 600;
        }
      }
      .priority-select {
        * {
          border: none !important;
        }
        &:hover {
          * {
            border: none !important;
          }
        }
        width: 264px;
        border: none;
        background: rgba(0, 72, 132, 0.12);
        height: 48px;
        font-size: 12px;
        .priority-label {
          @include label-medium;
          color: $Primary40;
        }
        svg {
          color: $Primary40;
        }
        .MuiSelect-select {
          padding: 0;
          margin: 0;
          @include spaced-between-flexbox(flex-start);
          align-items: center;
        }
        .MuiPaper-root {
          margin-top: 6px;
          @include select-dropdown;
          .MuiButtonBase-root {
            @include body-medium;
            .high-icon {
              color: #ffc043;
              margin-right: 18px;
            }
            .low-icon {
              color: #49454f;
              margin-right: 18px;
            }
          }
        }
      }
      .bottom-info {
        @include body-small;
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }
}

.note-menu {
  .MuiButtonBase-root {
    @include body-medium;
    @include spaced-between-flexbox(flex-start);
    gap: 10px;
  }
}
