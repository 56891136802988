.CalcLimitsTable {
    width: 100% !important;

    .limits-container {
        background: #F0F0F3 !important;
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }

    .limits-select {
        font-size: 1em;
        background: white;
    }

    .limits-key {
        font-weight: bold;
        text-align: left;
    }

    .limits-table-name {
        font-size: 1.2em;
    }

    .calc-limits-controls {
        display: grid;
        grid-template-columns: 1fr 0fr 1fr;
        padding: 1rem 1rem 0 1rem;

        .separator {
            width: 1px;
            background: var(--bs-gray-400);
            height: 80%;
            margin: 10% 1em;
        }

        .limit-control-section {

            .limit-control {
                display: flex;
                width: 100%;
                gap: 1rem;
                padding: 1rem 0;
                & > * {
                    flex: 1;
                }
            }
        }
    }
}
