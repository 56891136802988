@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.Tab1,
.Tab2,
.Tab3,
.Tab4,
.Tab5,
.Tab6 {
  margin-left: auto;
  margin-right: auto;
}

.Tab1 {
  max-width: 1520px;
  .inline-tables {
    display: flex;
    gap: 24px;
  }
  .CalcTable {
    .MuiTableHead-root {
      .MuiTableCell-root {
        &:first-child {
          @include spaced-between-flexbox(flex-start);
          text-align: left;
          padding-left: 16px;
          background: $LightSteel;
        }
      }
    }
  }
}

.Tab2 {
  max-width: 1400px;
  .CalcTable {
    width: 1400px;
  }
  .top-warning {
    @include body-small;
    @include spaced-between-flexbox(flex-start);
    border: 1px solid $InitioPurple;
    margin-top: 32px;
    padding-left: 28px;
    align-items: center;
    text-align: center;
    gap: 10px;
    width: 1400px;
    height: 48px;
  }
}

.Tab3 {
  max-width: 1340px;
  .CalcTable {
    width: 1340px;
    .MuiTableHead-root {
      .MuiTableRow-root {
        background: rgba(199, 203, 220, 0.5);
        border: none;
        &:first-child {
          .MuiTableCell-root {
            justify-content: center !important;
            align-items: center;
            height: 32px;
            border: none;
            border-top: none;
            border-bottom: 1px solid white;
            &:not(:first-child) {
              border-left: 1px solid white;
            }
          }
        }
      }
    }
    .MuiTableBody-root {
      .MuiTableCell-root {
        &:first-child {
          justify-content: flex-start !important;
          padding-left: 16px;
        }
      }
    }
  }
}

.Tab4,
.Tab5 {
  .no-sandbox-dialog,
  .no-report-dialog {
    margin-left: auto;
    margin-right: auto;
    width: 1000px;
    height: 220px;
    @include label-medium;
    @include spaced-between-flexbox(center, nowrap, column);
    p {
      font-weight: 400;
      &:first-child {
        font-weight: 600;
      }
      margin: 4px;
    }
    background: $Background1;
    border: 1px solid #79747e;
    color: #79747e;
    margin-top: 32px;
    align-items: center;
  }
}
