@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.AuditRiskSlider2 {
  margin-bottom: 52px;
  > .share-price-label {
    font-family: 'Roboto', Arial, Sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.075px;
    color: #49454F;
    position: relative;
    bottom: -80px;
  }
  > .mode-label {
    font-family: 'Roboto', Arial, Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #49454F;
    position: relative;
    bottom: -85px;
  }
  > .equidistantly-spaced-prices {
    font-family: 'Roboto', Arial, Sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.075px;
    color: #49454F;

    position: relative;

    bottom: -160px;

    z-index: 22;
  }
  .defensible-outcomes-price-wrapper {
    @include label-large;
    position: relative;
    top: -15px;
    z-index: 23;

    background-color: #42A179;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    border-radius: 8px;
    padding: 4px 14px;

    width: 64px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .defensible-outcomes-price {
      @include label-large;
      color: #FFFFFF;
    }
    &.medium-risk {
      background-color: #F4C543;
      .defensible-outcomes-price {
        color: #49454F;
      }
    }
  }
  .defensible-outcomes {
    position: relative;
    top: -10px;
    z-index: 23;

    background: #FDFEFF;
    box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
    border-radius: 8px;

    padding: 4px 8px;

    width: 82px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .defensible-outcomes-text {
      @include label-medium;
      &:first-child {
        @include label-large;
      }
    }
  }
  .slider {
    width: 544px;
    height: 30px;

    background: linear-gradient(
      90.03deg,
      rgba(240, 116, 37, 0.7) 0.04%,
      rgba(253, 229, 102, 0.7) 18.61%,
      rgba(209, 221, 110, 0.7) 26.35%,
      rgba(64, 196, 134, 0.7) 49.04%,
      rgba(205, 221, 110, 0.7) 72.77%,
      rgba(252, 229, 102, 0.7) 81.03%,
      rgba(240, 118, 37, 0.7) 99.08%,
    );
    border-radius: 24px;

    transform: rotate(180deg);
    .dotted-vertical {
      position: absolute;
      top: -7px;

      width: 0px;
      height: 45px;

      border: 1px dashed #999BA5;
    }
    .mode-vertical {
      position: absolute;
      top: -7px;

      width: 0px;
      height: 45px;

      border: 1px solid #999BA5;
    }
    .price-vertical {
      position: absolute;
      z-index: 23;
      height: 148px;
      left: 334px;
      top: -7px;
      border-radius: 2px;
      border: 1.5px solid #303D5C;
      box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85);
    }
    .dotted-vertical-label {
      font-family: 'Roboto', Arial, Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.075px;
      color: #49454F;

      position: relative;
      bottom: -60px;
      right: -300px;
      z-index: 22;
    }
  }
}
