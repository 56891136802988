@mixin shared-font-styles() {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  line-height: 20px;
}


.RightInfoBlock {
  width: 197px;
  height: 100px;
  background: #303D5C;
  border: 1px solid rgba(32, 32, 32, 0.2);
  box-shadow: 0px 4px 8px rgba(199, 203, 220, 0.85);
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  > .right-col-info-container {
    > .title {
      @include shared-font-styles();
      position: relative;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.075px;
      color: #D0D9EE;
      margin-bottom: 4px;
      display: block;
    }
    > .data-label {
      @include shared-font-styles();
      font-weight: 900;
      font-size: 16px;
      letter-spacing: 0.1px;
      color: #FDFEFF;
      display: block;
      &.loading {
        font-size: 16px;
        width: 20px;
        color: transparent;
      }
    }
  }
}
