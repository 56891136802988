@import "../../styles/palette.scss";
@import "../../styles/mixins.scss";
@import "../../styles/typography.scss";

.Archive {
  .progress-col {
    width: 1500px !important;
    padding: 27px 66px;
    .archive-grid {
      margin-top: 8px;
      width: 100%;
      display: grid;
      max-height: 640px;
      overflow-y: scroll;
      grid-template-columns: repeat(4, 1fr);
      justify-items: center;
      &::-webkit-scrollbar {
        width: 16px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
        border-radius: 100px;
        background-color: #49454f;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-corner {
        background: rgba(0,0,0,0);
      }
    }
  }
}

.select-admin-status-dropdown {
  @include select-dropdown;
  .MuiButtonBase-root {
    @include body-medium;
  }
}
