@import "../../styles/mixins.scss";
@import "../../styles/palette.scss";
@import "../../styles/typography.scss";

.Sandbox.PriceSandbox {
  .SharePriceSlider {
    margin-bottom: 52px;

    >.share-price-label {
      font-family: "Roboto", Arial, Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.075px;
      color: #49454f;
      position: relative;
      bottom: -80px;
    }

    >.mode-label {
      font-family: "Roboto", Arial, Sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #49454f;
      position: relative;
      bottom: -85px;
    }

    >.equidistantly-spaced-prices {
      font-family: "Roboto", Arial, Sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.075px;
      color: #49454f;
      position: relative;
      bottom: -160px;
      z-index: 22;
    }

    .defensible-outcomes-price-wrapper {
      @include label-large;
      position: relative;
      top: -15px;
      z-index: 23;

      background-color: #42a179;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      border-radius: 8px;
      padding: 4px 14px;

      width: 64px;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .defensible-outcomes-price {
        @include label-large;
        color: #ffffff;
      }

      &.medium-risk {
        background-color: #f4c543;

        .defensible-outcomes-price {
          color: #49454f;
        }
      }
    }

    .defensible-outcomes {
      position: relative;
      top: -10px;
      z-index: 23;
      background: #fdfeff;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      border-radius: 8px;
      padding: 4px 8px;
      width: 82px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .defensible-outcomes-text {
        @include label-medium;

        &:first-child {
          @include label-large;
        }
      }
    }
  }

  .slider-wrapper {
    position: relative;

    .min-price {
      position: absolute;
      margin-top: 2px;
      z-index: 25;
      height: 44px;
      left: 26px;
      top: 65px;
      border: transparent;
      border-right: 1px dashed #999ba5;
      background-color: transparent;
    }

    .max-price {
      position: absolute;
      margin-top: 2px;
      z-index: 25;
      height: 44px;
      right: 24px;
      top: 65px;
      width: 1px;
      border: transparent;
      border-right: 1px dashed #999ba5;
      background-color: transparent;
    }

    .slider-mirage {
      position: absolute;
      padding-top: 1px;
      top: 75px;
      width: 544px;
      height: 12px;
      border-radius: 24px;
      background: linear-gradient(
        to right,
        #303D5C 0%,
        #303D5C 1.5%,
        transparent 5%,
        transparent 98.5%,
        #cccccc86 98.5%,
        #CCCCCC86 100%
      );
      border: 1px solid #999BA5;
      // leaving for future reference
      // background: linear-gradient(
      //   90.03deg,
      //   rgba(240, 116, 37, 0.7) 0.04%,
      //   rgba(253, 229, 102, 0.7) 18.61%,
      //   rgba(209, 221, 110, 0.7) 26.35%,
      //   rgba(64, 196, 134, 0.7) 49.04%,
      //   rgba(205, 221, 110, 0.7) 72.77%,
      //   rgba(252, 229, 102, 0.7) 81.03%,
      //   rgba(240, 118, 37, 0.7) 99.08%
      // );

      &.finalized {
        border: 1px solid #999ba5;
        background: linear-gradient(
          to right,
          #CCCCCC 0%,
          #CCCCCC 1.5%,
          transparent 5%,
          transparent 98.5%,
          #cccccc86 98.5%,
          #CCCCCC86 100%
        );
      }
      &.single-price {
        background: #CCCCCC;
        border: 1px solid #999ba5;
        }
    }
  }

  .MuiSlider-root {
    display: flex;
    width: 525px !important;
    margin-bottom: 28px !important;
    color: transparent;
    height: 11px;
    border-radius: none;
    &.MuiSlider-root.finalized.MuiSlider-track {
      color: #CCCCCC !important;
    }

    .MuiSlider-root:hover .MuiSlider-thumb {
      transition: left 0.1s ease;
    }

    .MuiSlider-rail {
      background: #CCCCCC;
      border: transparent;
      border-radius: 0px;
    }

    >:nth-last-child(2) {
      margin-left: -12px !important;
    }

    >:nth-last-child(3) {
      border: transparent !important;
    }

    .MuiSlider-mark[data-index="0"]{
      border: transparent !important;
    }

    .MuiSlider-mark {
      margin-top: 4px;
      height: 50px;
      border: transparent;
      border-right: 1px dashed #999ba5;
      background-color: transparent;
    }

    .MuiSlider-mark {
      margin-top: 2px;
      height: 23px;
      border: transparent;
      border-right: 1px dashed #999ba5;
      background-color: transparent;
    }

    .MuiSlider-markLabel {
      margin-top: 27px;
      margin-left: 9px;
      font-weight: 600;
      color: #49454F;
    }

    .slider-thumb {
      display: flex;
      height: 48px;
      width: 24px;
      background: #007bcc;
      border-radius: 16px;
      z-index: 500;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      cursor: pointer !important;
            transition: width 0.1s ease;
      transition-timing-function: cubic-bezier(0.1, 0.5, 1.0, 0.1);

      .strike-through-btm {
        display: flex;
        position: absolute;
        height: 3.0rem;
        z-index: -1;
        top: 48px;
        width: 2px;
        background-color: #0061a4;
        border-radius: 0 0 1rem 1rem;

        &.finalized {
          background: #999BA5;
        }
      }

      .strike-through-top {
        display: flex;
        margin-top: 25px;
        position: absolute;
        bottom: 48px;
        height: 1.4rem;
        z-index: -1;
        width: 2px;
        background-color: #0061a4;
        border-radius: 1rem 1rem 0 0;

        &.finalized {
          background: #999BA5;
        }
      }

      .grip-container {
        display: flex;
        flex-direction: row;
        gap: 4px;
        width: 12px;

        &.finalized {
          background: #CCCCCC;
        }

        .airbnb-bar {
          display: flex;
          height: 1.5rem;
          z-index: 1;
          width: 1px !important;
          background-color: #63b0ff !important;
          border-radius: 1rem;

          &.finalized {
            background: #FFFFFF !important;
          }
        }
      }

      &:hover {
        box-shadow: 0 0 0 12px rgba(0, 72, 132, 0.08);
        background: #00497d;
      }

      &:active {
        box-shadow: 0 0 0 12px rgba(0, 72, 132, 0.16);
      }

      &::after {
        z-index: 500;
      }

      &.finalized {
        background: #CCCCCC;
        border: 2px solid #F0F0F3;
        box-shadow: none;
      }
    }

    .MuiSlider-valueLabel {
      line-height: 20px;
      font-size: 14px;
      background: "unset";
      font-weight: 600;
      padding: 0;
      width: 64px;
      height: 28px;
      border-radius: 8px;
      transform-origin: bottom left;
      box-shadow: 0px -4px 8px rgba(0, 49, 93, 0.2);
      transform: translate(10%, 0%) rotate(0degs) scale(0) !important;

      &::before {
        display: none;
      }

      &.MuiSlider-valueLabelOpen {
        transform: translate(100%, 150%) rotate(-180deg) scale(1);
      }

      &>* {
        transform: rotate(180deg);
      }
    }
  }
}
