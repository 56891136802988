@import '../../styles/mixins.scss';
@import '../../styles/palette.scss';
@import '../../styles/typography.scss';

.CalcForm409A {
  background: #f5f5f5;
  min-width: 1450px;
  .tables-pg-top-nav-buttons {
    @include spaced-between-flexbox(flex-start);
    gap: 1px;
    height: 48px;
    width: 100%;
    margin-bottom: 20px;
    border-top: 1px solid #fafafd;
    background-color: #c7cbdc;
    .top-nav-button {
      @include spaced-between-flexbox(center);
      @include body-large;
      align-items: center;
      padding-left: 12px;
      padding-right: 12px;
      gap: 10px;
      border: none;
      background-color: #c7cbdc;
      color: $Primary40;
      &.active {
        pointer-events: none;
        color: #49454f;
      }
      &:hover {
        cursor: pointer;
        background-color: #b9bdcd;
      }
      &:active {
        background-color: #abafbe;
      }
      &:focus-visible {
        background-color: #abafbe;
        outline: 1px solid $InitioBlue;
      }
    }
  }
  .search-bar-and-sort-dropdown {
    @include spaced-between-flexbox();
    align-items: center;
    width: 1384px;
    margin-left: auto;
    margin-right: auto;
    .search-field {
      input {
        @include body-medium;
        &::placeholder {
          @include body-medium;
          opacity: 1;
        }
      }
      margin-right: 20px;
      .MuiInputBase-root {
        * {
          box-shadow: none;
          border-radius: 8px;
        }
        width: 334px;
        transition: width 0.25s;
        &.Mui-focused {
          width: 684px;
          transition: width 0.25s;
        }
        height: 32px;
        background: #fafafd;
      }
    }
    .sort-by {
      * {
        border: none;
        box-shadow: none;
      }
      @include label-large;
      font-size: 12px;
      @include spaced-between-flexbox(flex-start);
      align-items: center;
      .MuiInputBase-root {
        * {
          border: none;
          color: $Primary40;
        }
        .MuiSelect-select {
          margin-right: 8px;
          @include label-large;
          color: $Primary40;
          padding: 0px 28px 0px 4px;
          font-size: 12px;
        }
        .MuiSvgIcon-root {
          padding: 2px;
        }
      }
    }
  }
  .progress-cols-wrapper {
    @include spaced-between-flexbox(center, wrap);
    gap: 16px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    .progress-col {
      height: 1080px;
      &.has-open {
        height: auto;
        min-height: 1080px;
      }
      overflow: hidden;
      width: 334px;
      background: rgba(199, 203, 220, 0.5);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      h3 {
        @include headline-medium;
        color: #49454f;
        margin-left: 16px;
        margin-top: 20px;
        margin-right: auto;
        margin-bottom: 28px;
      }
      .sort-by-status {
        * {
          border: none;
          box-shadow: none;
        }
        margin-right: 8px;
        @include spaced-between-flexbox(flex-start);
        align-items: center;
        @include label-large;
        font-size: 12px;
        margin-top: -24px;
        margin-left: auto;
        margin-bottom: 4px;
        .MuiInputBase-root {
          * {
            border: none;
            color: $Primary40;
          }
          @include spaced-between-flexbox(center);
          align-items: center;
          gap: 20px;
          .MuiSelect-select {
            margin-right: 8px;
            @include label-large;
            color: $Primary40;
            padding: 0px 28px 0px 4px;
            font-size: 12px;
          }
          .MuiSvgIcon-root {
            padding: 2px;
          }
        }
      }
    }
  }
  .bottom-pagination {
    &::before {
      content: '';
      box-shadow: 0 -1px 0 $Outline;
      height: 1px;
      width: 100%;
      position: absolute;
    }
    @include spaced-between-flexbox(center);
    gap: 16px;
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    min-width: 1200px;
    .MuiPagination-root {
      background-color: $Container2;
      opacity: 0.9;
      height: 50px;
      @include spaced-between-flexbox(center);
      width: 334px;
      box-shadow: 0px 4px 8px rgba(0, 49, 93, 0.2);
      &.hidden {
        .MuiPagination-ul {
          visibility: hidden;
        }
      }
      .MuiButtonBase-root {
        height: 28px;
        min-width: 28px;
        &:not(.MuiPaginationItem-previousNext):not(.MuiPaginationItem-firstLast) {
          background-color: $MainBackground;
          border-radius: 8px;
        }
        &.Mui-selected {
          color: white;
          background-color: #0061a4 !important;
        }
      }
    }
  }
}

.loading-inputs-dialog {
  .MuiPaper-root {
    overflow: hidden;
    border-radius: 24px;
    padding: 32px;
    @include label-large;
    .loading-wrapper {
      @include spaced-between-flexbox(center);
      margin: 20px;
      .custom-loading-spinner {
        $spinnerSize: 62px;
        height: $spinnerSize;
        width: $spinnerSize;
        @include spin-infinitely();
      }
    }
  }
}

.filter-by-dropdown {
  .MuiButtonBase-root {
    font-size: 14px;
    &.active {
      background-color: rgba(66, 149, 243, 0.08);
      box-shadow: 4px 0 0 $InitioBlue inset;
    }
  }
}
