@import "../../styles/mixins.scss";

@mixin slider-label() {
  background: #003E66;
  color: #fff;
}

.ValuationSlider {
  width: 500px;
  margin-top: 50px;
  .valuation-slider-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    > .left-side-label {
      width: 105px;
      margin-right: 15px;

      flex-wrap: nowrap;
      flex-direction: row;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      > h6 {
        font-family: "Manrope", sans-serif;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #49454F;
        margin-top: 8px;
        > svg {
          margin-top: -3px;
          margin-left: 3px;
        }
      }
    }
    > .valuation-slider-slide {
      width: 380px;
      margin-right: 15px;
    }
    .MuiSlider-root {
      height: 8px;
      .MuiSlider-mark {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #63B0FF;
        @include mui-slider-first-indicator-offset();
      }
    }
    .MuiSlider-track {
      background: #0061A4;
      border-radius: 100px 100px 100px 100px;
    }
    .MuiSlider-valueLabel {
      @include slider-label();
    }
    .MuiSlider-valueLabelOpen {
      &.MuiSlider-valueLabel {
        @include slider-label();
        border-radius: 8px;
      }
    }
  }
  .valuation-labels-wrapper {
    width: 387px;
    float: right;
    margin-top: -3px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    > span {
      font-family: 'Roboto', Arial, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.5px;
      color: #49454F;
    }
  }
}
